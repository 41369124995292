<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ title }}</h1>
    </header>
    <div class="page-body container mx-med">
      <section class="section-3">
        <div class="row">
          <InfoCard v-for="(info, index) in activeCampFinancial" :key="index" :obj="info" />
        </div>
      </section>
    </div>
    <camp-selector :disabled="true"></camp-selector>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import InfoCard from '@/components/info-card/InfoCard.vue'
import Loader from '@/components/treasury-tools/loader'
import modalMixin from '@/mixins/modalMixin'
import { securityMixin } from '@/mixins/securityMixin'
import { staffUserMixin } from '@/mixins/staffUserMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'treasury-tools',
  mixins: [modalMixin, translationMixin, securityMixin, staffUserMixin],
  props: {},
  data() {
    return {
      translations: {},
      title: '',
      secured_tile_view_controls: {
        treasury_monthly_camp_banking_tile: '27008401-c031-4593-a68b-0b06e0c5f378',
        treasury_monthly_state_banking_tile: 'ddcd1cb6-3394-4791-a1b6-450a62b6a244',
        treasury_state_e503_check_cash_bulk_tile: '514e605c-a4f1-487b-988d-58987211dce2',
        treasury_camp_e503_check_cash_bulk_tile: '2c870158-65d4-48d0-bae1-c0175706b22d',
        treasury_state_e503M_check_cash_bulk_tile: '514e605c-a4f1-487b-988d-58987211dce3',
        treasury_camp_e503M_check_cash_bulk_tile: '2c870158-65d4-48d0-bae1-c0175706b22e',
        treasury_state_pending_scripture_remittance_tile: '3ee172c5-3cc8-4663-b13d-6a9b64577b7d',
        treasury_camp_pending_scripture_remittance_tile: '902438de-ea05-4c61-b6cc-29fe2bace599',
        treasury_state_history_scripture_remittance_tile: '60eb31ea-0029-49b0-9c1f-c039f223248b',
        treasury_camp_history_scripture_remittance_tile: 'a1f03eb3-b291-4e90-93a8-d27bcd67516e',
        treasury_state_bank_account_tile: '3e97e4b9-c4cb-4686-8923-a241e582938e',
        treasury_camp_bank_account_tile: '7d18deed-9f6b-4bcf-9680-7b0837eae8b7',
      },
      campFinancial: [
        {
          display: true,
          class: 'col col-lg-6 col-md-6 col-sm-12',
          title: 'CAMP REMITTANCE (E503)',
          content: 'Funds submitted here will be withdrawn from the Camp/State Bank Account.',
          link: '',
          label: 'View More',
          img: {
            src: 'svgs/church-list.svg',
            alt: 'CAMP REMITTANCE (E503)',
            width: 64,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-lg-6 col-md-6 col-sm-12',
          title: 'MULTIPLE CAMP REMITTANCE (E503M)',
          content: 'Funds submitted here will be withdrawn from the Camp/State Bank Account.',
          link: '',
          label: 'View More',
          img: {
            src: 'svgs/church-list.svg',
            alt: 'MULTIPLE CAMP REMITTANCE (E503M)',
            width: 64,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-lg-6 col-md-6 col-sm-12',
          title: 'PENDING CAMP REMITTANCES (E503)',
          content: 'View transactions that are awaiting completion',
          link: '',
          label: 'View More',
          img: {
            src: 'svgs/church-list.svg',
            alt: 'PENDING CAMP REMITTANCES (E503)',
            width: 64,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-lg-6 col-md-6 col-sm-12',
          title: 'CAMP REMITTANCE HISTORY',
          content: 'See a list of completed camp remittances.',
          link: '',
          label: 'View More',
          img: {
            src: 'svgs/scroll.svg',
            alt: 'CAMP REMITTANCE HISTORY',
            width: 64,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-lg-6 col-md-6 col-sm-12',
          title: 'BANK ACCOUNT DETAILS',
          content: 'Setup or update camp bank account number and routing number.',
          link: '',
          label: 'View More',
          img: {
            src: 'svgs/coins.svg',
            alt: 'BANK ACCOUNT DETAILS',
            width: 64,
            height: 64,
          },
        },
        {
          display: false,
          class: 'col col-lg-6 col-md-6 col-sm-12',
          title: '', // monthly {state or camp} banking
          content: '',
          link: '',
          label: 'View More',
          img: {
            src: 'svgs/institution.svg',
            alt: '',
            width: 64,
            height: 64,
          },
        },
        {
          display: true,
          class: 'col col-lg-6 col-md-6 col-sm-12',
          title: 'PROGRAM RESOURCES',
          content: '',
          link: '/resources/media-portal',
          label: 'View More',
          img: {
            src: 'svgs/program-resources.svg',
            alt: 'PROGRAM RESOURCES',
            width: 64,
            height: 64,
          },
        },
      ],
    }
  },
  components: {
    campSelector: CampSelect,
    InfoCard: InfoCard,
    Loader,
  },
  methods: {
    ...mapActions({
      getAllStates: 'remittanceModule/getAllStates',
      getCamps: 'campModule/getCamps',
      getControlPermissionsFromList: 'user/getControlPermissionsFromList',
      getRemittanceAnnouncement: 'remittanceModule/getRemittanceAnnouncement',
      processPreauth: 'remittanceModule/processPreauth',
      resetHomeCamp: 'campModule/resetHomeCamp',
      resetTheRemittanceKey: 'remittanceModule/resetTheRemittanceKey',
      retrieveUserData: 'userModule/retrieveUserData',
      setCurrentCampByKey: 'campModule/setCurrentCampByKey',
      setHomeCamp: 'campModule/setHomeCamp',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedRemittanceKey: 'remittanceModule/setSelectedRemittanceKey',
      setStateOrCamp: 'campModule/setStateOrCamp',
    }),
    createTitle(code) {
      return {
        s: this.translations.tcStateTreasuryTools,
        c: this.translations.tcCampTreasuryTools,
      }[code || 'c']
    },
    createText(code) {
      return {
        s: 'State',
        c: 'Camp',
      }[code || 'c']
    },
    async applyStateOrCamp() {
      let camp_state = this.createText(this.stateOrCamp)
      this.campFinancial[0].link = `/officers/treasury-tools/e503View/${this.stateOrCamp}`
      this.campFinancial[1].link = `/officers/treasury-tools/e503M/${this.stateOrCamp}`
      this.campFinancial[2].link = `/officers/${camp_state}/treasury-tools/PendingRemittance/1/link`
      this.campFinancial[3].link = `/officers/${camp_state}/treasury-tools/PendingRemittance/2/link`
      this.campFinancial[4].link = `/officers/${camp_state}/treasury-tools/BankAccount/link`
      this.campFinancial[5].link = `/officers/${camp_state}/treasury-tools/Activity/link`

      let title =
        this.stateOrCamp === 's'
          ? this.translations.tcMonthlyStateBankingTitle
          : this.translations.tcMonthlyCampBankingTitle

      this.campFinancial[5].title = title
      this.campFinancial[5].img.alt = title

      let description =
        this.stateOrCamp === 's'
          ? this.translations.tcMonthlyStateBankingDescription
          : this.translations.tcMonthlyCampBankingDescription

      this.campFinancial[5].content = description
    },
    async page_load() {
      await Promise.all([
        await this.setStateOrCamp(this.$route.path.split('/')[4]).then(() => {
          this.applyStateOrCamp()
        }),
        await (async () => {
          if (this.setStateOrCamp === 's') {
            await this.setCurrentCampByKey(this.stateUndist.cmp_org_key)
          } else {
            await this.setHomeCamp({
              key: this.userCamp.key,
              text: this.userCamp.name,
              value: this.userCamp.number,
            })
            await this.setCurrentCampByKey(this.officerToolbarSelected.camp)
          }
        })(),
        await this.getAllStates(),
      ])
    },
    loadTranslations() {
      const stateOrCamp = this.createText(this.$route.path.split('/')[4])
      this.title = this.createTitle(stateOrCamp)
      this.campFinancial[0].title = this.stateOrCamp === 's' ? this.translations.tcStateE503CheckAndCashBulkRemittanceTitle : this.translations.tcE503CheckAndCashBulkRemittanceTitle
      if (stateOrCamp === 'State') {
        this.campFinancial[0].content = this.translations.tcE503CheckAndCashBulkRemittanceStateDescription
      } else if (stateOrCamp === 'Camp') {
        this.campFinancial[0].content = this.translations.tcE503CheckAndCashBulkRemittanceCampDescription
      } else {
        this.campFinancial[0].content = ''
      }
      this.campFinancial[0].label = this.translations.tcViewMore
      this.campFinancial[0].img.alt = this.translations.tcE503CheckAndCashBulkTitle

      this.campFinancial[1].title = this.translations.tcE503MCheckAndCashBulkRemittanceTitle
      if (stateOrCamp === 'State') {
        this.campFinancial[1].content = this.translations.tcE503MCheckAndCashBulkRemittanceStateDescription
      } else if (stateOrCamp === 'Camp') {
        this.campFinancial[1].content = this.translations.tcE503MCheckAndCashBulkRemittanceCampDescription
      } else {
        this.campFinancial[1].content = ''
      }
      this.campFinancial[1].label = this.translations.tcViewMore
      this.campFinancial[1].img.alt = this.translations.tcE503MCheckAndCashBulkTitle
      this.campFinancial[2].title = this.stateOrCamp === 's' ? this.translations.tcPendingStateE503ScriptureRemittancesTitle : this.translations.tcPendingE503ScriptureRemittancesTitle
      this.campFinancial[2].content = this.translations.tcPendingE503ScriptureRemittancesDescription
      this.campFinancial[2].label = this.translations.tcViewMore
      this.campFinancial[2].img.alt = this.translations.tcPendingE503ScriptureRemittanceTitle
      this.campFinancial[3].title = this.stateOrCamp === 's' ? this.translations.tcStateScriptureRemittanceHistoryTitle : this.translations.tcScriptureRemittanceHistoryTitle
      this.campFinancial[3].content = this.stateOrCamp === 's' ? this.translations.tcStateScriptureRemittanceHistoryDescription : this.translations.tcScriptureRemittanceHistoryDescription
      this.campFinancial[3].label = this.translations.tcViewMore
      this.campFinancial[3].img.alt = this.translations.tcScriptureRemittanceTitle
      this.campFinancial[4].title = this.translations.tcBankAccountTitle
      this.campFinancial[4].content = this.translations.tcBankAccountDescription
      this.campFinancial[4].label = this.translations.tcViewMore
      this.campFinancial[4].img.alt = this.translations.tcBankAccountTitle
      // settings for Monthly {State|Camp} Banking
      this.campFinancial[5].label = this.translations.tcViewMore
      // other attributes set by applyStateOrCamp()
      this.campFinancial[6].title = this.translations.tcProgramResourcesTitle
      this.campFinancial[6].content = this.translations.tcProgramResourcesDescription
      this.campFinancial[6].label = this.translations.tcViewMore
      this.campFinancial[6].img.alt = this.translationstcProgramResourcesTitle
    },
    async accessibilityCheck() {
      let tile_list =
        this.$route.path.split('/')[4] === 's'
          ? [
            { a: this.secured_tile_view_controls.treasury_state_e503_check_cash_bulk_tile, b: 0 },
            { a: this.secured_tile_view_controls.treasury_state_e503M_check_cash_bulk_tile, b: 1 },
            { a: this.secured_tile_view_controls.treasury_state_pending_scripture_remittance_tile, b: 2 },
            { a: this.secured_tile_view_controls.treasury_state_history_scripture_remittance_tile, b: 3 },
            { a: this.secured_tile_view_controls.treasury_state_bank_account_tile, b: 4 },
            { a: this.secured_tile_view_controls.treasury_monthly_state_banking_tile, b: 5 },
          ]
          : [
            { a: this.secured_tile_view_controls.treasury_camp_e503_check_cash_bulk_tile, b: 0 },
            { a: this.secured_tile_view_controls.treasury_camp_e503M_check_cash_bulk_tile, b: 1 },
            { a: this.secured_tile_view_controls.treasury_camp_pending_scripture_remittance_tile, b: 2 },
            { a: this.secured_tile_view_controls.treasury_camp_history_scripture_remittance_tile, b: 3 },
            { a: this.secured_tile_view_controls.treasury_camp_bank_account_tile, b: 4 },
            { a: this.secured_tile_view_controls.treasury_monthly_camp_banking_tile, b: 5 },
          ]
      await this.getControlPermissionsFromList(tile_list.map((tl) => tl.a))
      if (this.isStaffMember) {
        tile_list.map((sb) => {
          this.campFinancial[sb.b].display = true
        })
      } else {
        tile_list.map((sb) => {
          this.campFinancial[sb.b].display = this.determineAccessibility(sb.a)
        })
      }
    },
  },
  computed: {
    ...mapGetters({
      getUser: 'userModule/getUser',
      homeCamp: 'campModule/homeCamp',
      iCanSee: 'user/iCanSee',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      stateOrCamp: 'campModule/stateOrCamp',
      stateUndist: 'campModule/stateUndist',
      userCamp: 'user/userCamp',
      userId: 'user/userId',
      userLogin: 'user/userLogin',
      userStateKey: 'user/userStateKey',
    }),
    activeCampFinancial() {
      return this.campFinancial.filter((cff) => cff.display === true)
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await this.processPreauth()
      await this.page_load()
      await this.getViewTranslations()
      this.loadTranslations()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  async beforeUpdate() {
    this.setLoadingStatus(true)
    const fourth = this.$route.path.split('/')[4]
    await Promise.all([
      await this.setStateOrCamp(fourth).then(() => {
        this.applyStateOrCamp()
        this.title = this.createTitle(fourth)
        this.accessibilityCheck()
      }),
    ]).then(() => {
      this.setLoadingStatus(false)
    })
  },
  async mounted() {
    if (!!this.userStateKey) {
      await this.getCamps(this.userStateKey)
    }
    await (async () => {
      await this.setSelectedRemittanceKey('')
      if (!this.getUser || !this.getUser.camp_number) {
        if (!!this.userId) {
          await this.retrieveUserData({
            ind_key: this.userId,
            camp_key: this.userCamp.key,
          })
        } else {
          console.error('error')
        }
      }
    })()
  },
}
</script>

<style lang="scss">
.nonew {
  padding: 30px;
}

@import '@/styles/settings.scss';

.membership {
  .page-body {
    margin-top: 60px;

    h2 {
      margin-bottom: 36px;
    }
  }

  .section-1,
  .section-2 {
    margin-bottom: 52px;

    >.row {
      margin-bottom: 40px;
    }

    .t-container {
      @include breakpoint(sm) {
        margin-bottom: 1rem;
      }
    }

    .col {
      @include breakpoint(sm) {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }

      &:first-of-type {
        padding-right: 23px;
      }

      &:last-of-type {
        padding-left: 23px;

        .number-card:last-of-type {
          margin-bottom: 0;
        }
      }

      table {
        width: 100% !important;
      }
    }
  }

  .section-3 {
    margin-bottom: 60px;

    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }

      &:nth-child(2n) {
        padding-left: 23px;
      }
    }

    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
</style>
